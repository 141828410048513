/*
 * Filename: typo.css
 *
 * Primary stylesheet which imports every other file.

  font-family:'Tahoma W01 Regular';
  font-family:'Tahoma W01 Bold';

 */

body {
  font-family:'Tahoma W01 Regular';
  font-size: var(--font-size);
  font-weight: normal;
  line-height: var(--line-height);

  color: var(--font-color);
  letter-spacing: -0.02em;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin-top: 0;
  margin-bottom: var(--gutter-horizontal);
}

p,
ul {
  font-weight: normal;
  line-height: var(--line-height);
}

h1 {
  font-size: var(--font-size-h3);
  font-weight: normal;
  line-height: calc(var(--line-height) * 0.7);
}

h2 {
  font-size: var(--font-size-h3);
  font-weight: normal;
  line-height: calc(var(--line-height) * 0.7);
}

h3 {
  font-size: var(--font-size-h3);
  font-weight: normal;
  line-height: calc(var(--line-height) * 0.7);
}

h4 {
  font-size: var(--font-size-h4);
  font-weight: normal;
  line-height: calc(var(--line-height) * 0.8);
}

h5 {
  font-size: var(--font-size-h5);
  font-weight: normal;
  line-height: calc(var(--line-height) * 0.8);
}

h6 {
  font-family:'Tahoma W01 Bold';
  font-size: var(--font-size-h6);
  font-weight: normal;
  line-height: calc(var(--line-height) * 0.8);
}

small {
  font-size: var(--font-size-small);
}

ul {
  padding-left: var(--gutter-horizontal);
}

a {
  text-decoration: none;
  color: var(--color-primary);
}

a:hover {
  color: var(--color-primary-hover);
}

button {
  border: 0;
}

@media screen and (--viewport-md) {
  h1 {
    font-size: var(--font-size-h2);
    line-height: calc(var(--line-height) * 0.75);
  }

  h2 {
    font-size: var(--font-size-h2);
    font-weight: normal;
    line-height: calc(var(--line-height) * 0.75);
  }

  h3 {
    font-size: var(--font-size-h3);
  }
}

@media screen and (--viewport-lg) {
  h1 {
    font-size: var(--font-size-h1);
    line-height: calc(var(--line-height) * 0.8);
  }
}
