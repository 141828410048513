/* allgemeine Infos und viewport-sx 320px */
footer img {
  display: block;
  width: 100%;
  height: 100%;
}

.main-footer-wrapper {
  background-color: var(--color-grey);
  padding-top: var(--gutter-horizontal);
  padding-bottom: var(--gutter-horizontal);
}

.footer-content {
  margin-top: var(--gutter-horizontal);
}

.footer-content:first-of-type {
  margin-top: 0;
}

.footer-content h5 {
  margin-bottom: var(--gutter);
}

.footer-content p,
.footer-content ul,
.footer-content a {
  font-size: var(--font-size-small);
}

.footer-content p {
  margin-bottom: calc(var(--gutter)*0.5);
}

.footer-content p.kontakt-fax {
  margin-bottom: 0;
}

.footer-content a {
  display: block;
  color: var(--font-color);
}

.footer-content a:hover {
  color: var(--color-primary);
}

.footer-content ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.footer-content li {
  margin-top: calc(var(--gutter)*0.3);
}

.footer-content li:first-of-type {
  margin-top: 0;
}

/* Subfooter */
.sub-footer-wrapper {
  padding-top: var(--gutter);
  padding-bottom: var(--gutter);
  background-color: var(--color-primary);

}

.sub-footer-wrapper p,
.sub-footer-wrapper a {
  color: white;
  font-size: var(--font-size-small);
}

.sub-footer-wrapper p {
  margin-bottom: calc(var(--gutter)*0.5);
}

.sub-footer-wrapper p:last-of-type {
  margin-bottom: 0;
}

.sub-footer-wrapper a:hover {
  color: var(--font-color);
}

/* viewport-sm 568px */
@media screen and (min-width: 35.5rem) {
  .main-footer-wrapper {
    padding-top: calc(var(--gutter)*3);
    padding-bottom: calc(var(--gutter)*3);
  }

  .footer-content-wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .footer-content:first-of-type {
    width: 48%;
    margin-right: 4%;
  }

  .footer-content:nth-of-type(2) {
    width: 48%;
    margin-top: 0;
  }

  .footer-content:nth-of-type(3) {
    width: 100%;
  }

  .footer-content h5 {
    margin-bottom: var(--gutter-horizontal);
  }

  .footer-content p {
    margin-bottom: calc(var(--gutter)*0.8);
  }

  /* Subfooter */
  .sub-footer-wrapper p {
    text-align: center;
  }
}

/* viewport-md 768px */
@media screen and (min-width: 48rem) {
  .footer-content-wrapper {
    justify-content: space-around;
  }

  .footer-content:first-of-type {
    width: 30%;
    margin-right: 0;
  }

  .footer-content:nth-of-type(2) {
    width: 15%;
    margin-top: 0;
  }

  .footer-content:nth-of-type(3) {
    width: 30%;
    margin-top: 0;
  }
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
  footer {
    margin-bottom: 0;
  }

  .footer-content:first-of-type {
    width: 25%;
  }

  .footer-content:nth-of-type(3) {
    width: 38%;
  }
}

/* viewport-xl 1280px */
@media screen and (min-width: 80rem) {
  .main-footer-wrapper {
    padding-top: calc(var(--gutter)*4);
    padding-bottom: calc(var(--gutter)*4);
  }

  .footer-content:nth-of-type(3) {
    width: 31%;
  }
}

/* viewport-xxl 1600px */
@media screen and (min-width: 100rem) {
  .footer-content:first-of-type {
    width: 20%;
  }

  .footer-content:nth-of-type(2) {
    width: 10%;
    margin-top: 0;
  }
}
