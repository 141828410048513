/* allgemeine Infos und viewport-sx 320px */
.swiper-container {
  background-image: url('img/slider-hg.jpg');
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  height: 12rem;
}

.swiper-slide {
  position: relative;
}

.swiper-slide img {
  display: none;
}

.slider-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-top: var(--gutter-horizontal);
  padding-right: var(--gutter);
  padding-left: var(--gutter);
}

.slider-content h1,
.slider-content h2,
.slider-content p {
  display: inline-block;
  font-family:'Tahoma W01 Regular';
  font-weight: normal;
  color: var(--color-primary);
  padding: calc(var(--gutter)*0.5) var(--gutter);
  background-color: white;
}

.slider-content h1,
.slider-content h2 {
  font-size: var(--font-size-h5);
  margin-bottom: calc(var(--gutter)*0.3);
}

.slider-content p {
  padding: calc(var(--gutter)*0.3) var(--gutter);
  margin-bottom: 0;
}

.slider-content a {
  margin-top: var(--gutter);
}

/* viewport-sm 568px */
@media screen and (min-width: 35.5rem) {
  .swiper-slide img {
    display: block;
    width: 100%;
    height: auto;
  }

  .swiper-container {
    height: auto;
  }

  .slider-content {
    position: absolute;
    width: 60%;
    bottom: var(--gutter-horizontal);
    right: var(--gutter);
    align-items: flex-end;
    padding-top: 0;
  }

  .slider-content h1,
  .slider-content h2 {
    font-size: var(--font-size-h4);
  }
}

/* viewport-md 768px */
@media screen and (min-width: 48rem) {
  .slider-content h1,
  .slider-content h2 {
    font-size: var(--font-size-h3);
  }

  .slider-content p {
    font-size: var(--font-size-h5);
  }
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
  .slider-content {
    bottom: calc(var(--gutter)*2.5);
  }

  .slider-content h1,
  .slider-content h2 {
    font-size: var(--font-size-h2);
  }

  .slider-content p {
    font-size: var(--font-size-h4);
  }

  .slider-content a {
    font-size: var(--font-size-h5);
  }

}

/* viewport-xl 1280px */
@media screen and (min-width: 80rem) {
  .slider-content {
    bottom: calc(var(--gutter)*3.5);
  }

  .slider-content h1,
  .slider-content h2 {
    font-size: var(--font-size-h1);
  }

  .slider-content p {
    font-size: var(--font-size-h3);
  }
}

/* viewport-xxl 1600px */
@media screen and (min-width: 100rem) {
  .slider-content {
    bottom: calc(var(--gutter)*4.5);
    right: 10%;
  }
}

/* zusätzlicher Viewport 1900px */
/*@media screen and (min-width: 118.75rem) {
  .slider-content {
    bottom: calc(var(--gutter)*4.5);
    right: 15%;
  }
}*/

/* zusätzlicher Viewport 2200px */
/*@media screen and (min-width: 137.5rem) {
  .slider-content {
    bottom: calc(var(--gutter)*4.5);
    right: 25%;
  }
}*/

/* zusätzlicher Viewport 2560px */
/*@media screen and (min-width: 160rem) {
  .swiper-container {
    height: 56rem;
    overflow: hidden;
  }

  .slider-content {
    top: calc(var(--gutter)*30);
    bottom: auto;
  }
}*/
