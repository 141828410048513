/* allgemeine Infos und viewport-sx 320px */
.info-wrapper {
  padding-top: calc(var(--gutter)*2);
  padding-bottom: calc(var(--gutter)*2);
}

.info-wrapper article {
  padding-top: 0;
  padding-bottom: 0;
}

.info-wrapper .info-box {
  margin-bottom: calc(var(--gutter)*3);
}

.info-wrapper .info-box .content-above img {
  width: 100%;
  height: auto;
}

.info-box .layout-3 a.oneImg {
  width: 100%;
  margin-right: 0;
}

.info-box ul {
  list-style-type: none;
  padding-left: 0;
}

.info-box li {
  padding-left: calc(var(--gutter)*0.7);
  text-indent: -0.7rem;
}

.info-box li::before {
  content: '•';
  color: var(--color-primary);
  margin-right: calc(var(--gutter)*0.4);
}

/* viewport-sm 568px */
@media screen and (min-width: 35.5rem) {
  .info-wrapper {
    padding-top: calc(var(--gutter)*4);
    padding-bottom: calc(var(--gutter)*4);
  }

  .info-box .content-above {
    display: flex;
    width: 100%;
    height: 15rem;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: var(--gutter);
  }

  .info-wrapper .info-box .content-above img {
    margin-bottom: 0;
  }
}

/* viewport-md 768px */
@media screen and (min-width: 48rem) {
  .info-box .content-above {
    height: 20rem;
  }
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
  .info-box article {
    margin-right: calc(var(--gutter)*2);
  }

  .info-box .content-above {
    height: 15rem;
  }
}

/* viewport-xl 1280px */
@media screen and (min-width: 80rem) {
  .info-wrapper {
    padding-top: calc(var(--gutter)*5);
    padding-bottom: calc(var(--gutter)*5);
  }

  .info-box article {
    margin-right: calc(var(--gutter)*3);
  }

  .info-box .content-above {
    height: 18rem;
  }

  .info-box .layout-3 a.oneImg {
    width: 100%;
    margin-right: 0;
  }
}

/* viewport-xxl 1600px */
@media screen and (min-width: 100rem) {
  .info-box .content-above {
    height: 22rem;
  }
}
