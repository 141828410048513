/* allgemeine Infos und viewport-sx 320px */
.news-box .content-above {
  position: relative;
}

.news-box {
  margin-bottom: var(--gutter-horizontal);
}

.news-box .layout-3 a.oneImg {
  width: 100%;
  margin-right: 0;
}

.news-box .news-date,
.news .news-date {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: var(--gutter-horizontal);
  left: 0;
  width: 4rem;
  height: 4rem;
  background-color: var(--color-primary);
}

.news-box .news-date p,
.news .news-date p {
  font-family:'Tahoma W01 Bold';
  font-weight: normal;
  color: white;
  margin-bottom: 0;
}

.news-box .news-date p:first-of-type,
.news .news-date p:first-of-type {
  font-size: 1.6rem;
}

.news-box .news-date p:last-of-type,
.news .news-date p:last-of-type {
  margin-top: -0.625rem;
}

/* viewport-sm 568px */
@media screen and (min-width: 35.5rem) {
  .news-box {
    margin-bottom: 0;
  }

  .news-box:first-of-type article {
    margin-right: var(--gutter);
  }

  .news-box:nth-of-type(2) article {
    margin-left: var(--gutter);
  }
}

/* viewport-md 768px */
@media screen and (min-width: 48rem) {
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
  .news-box .content-below p {
    font-size: var(--font-size);
  }
}

/* viewport-xl 1280px */
@media screen and (min-width: 80rem) {
  .news-box .layout-3 .content-above a.oneImg {
    width: 100%;
    margin-right: 0;
  }

  .news .news-date {
    left: 3rem;
  }
}

/* viewport-xxl 1600px */
@media screen and (min-width: 100rem) {
  .news .news-date {
    left: 8rem;
  }
}
