/* allgemeine Infos und viewport-sx 320px */

.header-wrapper {
  padding: var(--gutter);
}

.header-logo {
  display: flex;
  width: 100%;
  justify-content: center;
}

.header-logo img {
  width: 18rem;
  height: 100%;
}

.header-kontakt {
  margin-top: var(--gutter);
}

.header-kontakt a {
  display: block;
  font-size: var(--font-size-h5);
  text-align: center;
  color: var(--color-primary);
}

.header-kontakt img {
  width: 1rem;
  height: 100%;
}

/* Navigation */
.main-nav {
  background-color: var(--color-primary);
}

.hamburger-button {
  outline: none;
  border: medium none;
  color: var(--color-grey);
  font-weight: bold;
  padding: 0.5rem;
  background-color: var(--color-primary);
  cursor: pointer;
}

.hamburger-button:hover {
  background-color: var(--color-primary-hover);
}

.hamburger-button svg {
  width: var(--font-size-h3);
  height: var(--font-size-h3);
  display: block;
  margin: 0 auto;
}

.mobile-page-title {
  display: flex;
  align-items: center;
  color: white;
  margin-bottom: 0;
  padding-left: var(--gutter);
  font-size: var(--font-size-p);
}

.main-navigation li:hover {
  background-color: var(--color-primary);
}

ul.main-navigation {
  list-style-type: none;
  padding-left: 0;
}

.main-navigation li a img {
  display: block;
  width: 13.75rem;
  height: auto;
  margin-top: var(--gutter);
}

.dropdown {
  position: absolute;
  left: 0;
  background-color: var(--color-background);
  margin-bottom: 0;
  width: 100%;
  padding: var(--gutter-horizontal);
  list-style-type: none;
}

.dropdown-list-item {
  padding: 0 var(--gutter);
}

.dropdown > li:last-of-type {
  border-right: none;
}

.dropdown > li:first-of-type {
  padding-left: 0;
}

.dropdown-list-item ul {
  font-size: var(--font-size-small);
  list-style-type: disc;
}

.dropdown-link {
  display: block;
  color: var(--font-color);
  text-decoration: none;
}

.submenu-2 {
  margin-bottom: var(--gutter-horizontal);
}

.dropdown-link-layer-2 {
  color: var(--font-color);
  text-decoration: none;
}

.dropdown-link:hover,
.dropdown-link-layer-2:hover {
  color: var(--color-primary);
}

.main-navigation .open {
  background-color: var(--color-primary-nav);
  color: white;
}

.main-navigation .open + .dropdown {
  display: flex;
  flex-wrap: wrap;
  z-index: 10;
}

/* viewport-sm 568px */
@media screen and (min-width: 35.5rem) {
  .header-wrapper {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }

  .header-logo {
    display: flex;
    width: 17rem;
    justify-content: flex-start;
  }

  .header-logo img {
    width: 100%;
    height: 100%;
  }

  .header-kontakt {
    margin-top: 0;
  }

  .header-kontakt a {
    width: auto;
    text-align: left;
  }
}

/* viewport-md 768px */
@media screen and (min-width: 48rem) {
  header {
    position: fixed;
    width: 100%;
    z-index: 100;
    background-color: white;
  }

  .placeholder {
    height: 6.7rem;
    width: 100%;
  }

  .header-kontakt a {
    display: inline-block;
    margin-left: var(--gutter-horizontal);
  }

  /* Nav */
  .main-nav {
    box-shadow:0px 4px 5px 0px rgba(0,0,0, 0.1);
  }

  ul.main-navigation {
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
  }

  ul.main-navigation li {
    position: relative;
    display: inline-block;
    padding: calc(var(--gutter)*0.5) var(--gutter);
    margin-left: -0.2rem;
  }

   ul.main-navigation li .first-layer {
    color: white;
   }

  ul.main-navigation li:first-of-type {
    margin-left: 0;
  }

  ul.main-navigation li:hover,
  ul.main-navigation li.active {
    background-color: white;
  }

  ul.main-navigation li a {
    color: var(--color-secondary);
    padding: 0;
  }

  ul.main-navigation li a:hover,
  ul.main-navigation li.active a,
  ul.main-navigation li:hover a {
    color: var(--color-primary);
    background-color: transparent;
  }

  .main-navigation li a img {
    width: 10rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: calc(var(--gutter)*0.5);
  }

  /* 2. Ebene */
  .dropdown {
    display: none;
    position: absolute;
    left: 0;
    top: 2.5rem;
    background-color: var(--color-primary);
    margin-bottom: 0;
    width: 15rem;
    padding: 0;
    list-style-type: none;
    box-shadow: 0px 4px 5px 0px rgba(0,0,0, 0.2);
    z-index: 10;
  }

  ul.dropdown li.active .dropdown-link,
  ul.dropdown li .dropdown-link,
  ul.dropdown li .dropdown-link-layer-2 {
    color: var(--font-color);
    font-size: var(--font-size);
  }

  ul.dropdown li {
    display: block;
    width: 100%;
    padding: calc(var(--gutter)*0.5) var(--gutter);
    margin-left: 0;
    font-size: var(--font-size-p);
    background-color: white;
    border-right: 0;
  }

  ul.dropdown li:first-of-type {
    padding-left: var(--gutter);
  }

  ul.dropdown li:hover,
  ul.dropdown li.active  {
    background-color: var(--color-primary);
    color: white;
  }

  ul.dropdown li.active .dropdown-link,
  ul.dropdown li:hover .dropdown-link,
  ul.dropdown li:hover .dropdown-link-layer-2 {
    color: white;
  }

  .main-navigation .open {
    background-color: transparent;
    color: var(--color-primary);
  }
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
  .header-kontakt a {
    font-size: var(--font-size-h5);
  }

  .header-kontakt img {
    width: 1.1rem;
    vertical-align: baseline;
  }

  .header-logo {
    width: 17rem;
  }

  /* Nav 2. Ebene */
  .dropdown {
    width: 16rem;
    top: 2.5rem;
  }
}

/* viewport-xl 1280px */
@media screen and (min-width: 80rem) {
  .placeholder {
    height: 7.15rem;
    width: 100%;
  }

  .header-logo {
    width: 20rem;
  }

  .header-kontakt a {
    font-size: 1.4rem;
  }

  .header-kontakt img {
    width: 1.4rem;
    vertical-align: baseline;
  }

  /* Nav */
  ul.main-navigation li {
    font-size: 1.15rem;
    padding: calc(var(--gutter)*0.5) var(--gutter);
  }
}

/* viewport-xxl 1600px */
@media screen and (min-width: 100rem) {
  .placeholder {
    height: 6.55rem;
    width: 100%;
  }

  /* Nav */
  ul.main-navigation li {
    padding: calc(var(--gutter)*0.4) var(--gutter);
  }

  .dropdown {
    top: 2.3rem;
  }
}

