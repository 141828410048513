/* allgemeine Infos und viewport-sx 320px */
/* Layouts */
article {
  padding-top: var(--gutter-horizontal);
  padding-bottom: var(--gutter-horizontal);
}

.content-wrapper article a {
  color: var(--color-primary);
}

.content-wrapper article a:hover {
  color: var(--color-primary-hover);
}

.content-wrapper article ul {
  list-style-type: none;
  padding-left: 0;
}

.content-wrapper article li {
  padding-left: calc(var(--gutter)*0.7);
  text-indent: -0.7rem;
}

.content-wrapper article li::before {
  content: '•';
  color: var(--color-primary);
  margin-right: calc(var(--gutter)*0.4);
}

.layout-title {
  width: 100%;
  border-bottom: 2px solid var(--color-grey);
  margin-bottom: var(--gutter-horizontal);
}

.layout-title h2,
.layout-title h3,
.layout-title h4 {
  text-align: center;
  color: var(--color-primary);
}

.layout-2 .content-left {
  margin-bottom: var(--gutter);
}

.layout-5 .content-left {
  margin-bottom: var(--gutter-horizontal);
}

  .layout-1 h2,
  .layout-2 h2,
  .layout-3 h2,
  .layout-4 h2,
  .layout-5 h2 {
    width: 100%;
  }

  .layout-1 h3,
  .layout-2 h3,
  .layout-3 h3,
  .layout-4 h3,
  .layout-5 h3 {
    width: 100%;
  }

  .layout-1 h4,
  .layout-2 h4,
  .layout-3 h4,
  .layout-4 h4,
  .layout-5 h4 {
    width: 100%;
  }

  .layout-1 h5,
  .layout-2 h5,
  .layout-3 h5,
  .layout-4 h5,
  .layout-5 h5 {
    width: 100%;
  }

  .layout-1 h6,
  .layout-2 h6,
  .layout-3 h6,
  .layout-4 h6,
  .layout-5 h6 {
    width: 100%;
  }

.layout-5 h2,
.layout-5 h3,
.layout-5 h4,
.layout-5 h5,
.layout-5 h6 {
  color: var(--color-primary);
  border-bottom: 2px solid var(--color-grey);
  padding-bottom: var(--gutter);
  margin-bottom: var(--gutter);
}

.layout-5-title h2,
.layout-5-title h3,
.layout-5-title h4 {
  color: var(--color-primary);
  border-bottom: 0;
  padding-bottom: var(--gutter);
  margin-bottom: var(--gutter);
}

.content-left a img,
.content-right a img,
.content-above a img {
  margin-bottom: 1rem;
}

.content-right a:last-of-type img {
  margin-bottom: 0;
}

.layout-1  a.oneImg,
.layout-2  a.oneImg {
  width: 100%;
  margin-right: 0;
}

.content-wrapper ul li {
  margin-top: calc(var(--gutter)*0.5);
}

.content-wrapper ul li:first-of-type {
  margin-top: 0;
}

/* nächste Seite */
.next-page-wrapper {
  border: 2px solid var(--color-grey);
  padding-top: var(--gutter);
  padding-bottom: var(--gutter);
  margin-top: var(--gutter);
  margin-bottom: var(--gutter);
}

.next-page-wrapper p {
  text-align: center;
  margin-bottom: calc(var(--gutter)*0.15);
}

.next-page-wrapper a {
  color: var(--color-primary);
}

.next-page-wrapper a:hover {
  color: var(--color-primary-hover);
}

.next-page-wrapper span {
  font-size: var(--font-size-h4);
}


/* Bildnachweis Impressum */
.bildnachweis {
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.bildnachweis img {
  background-color: var(--color-grey);
}

/* viewport-sm 568px */
@media screen and (min-width: 35.5rem) {
  article {
    padding-top: calc(var(--gutter)*3);
    padding-bottom: calc(var(--gutter)*3);
  }

  /* Layouts */
  .layout-1 .content-left,
  .layout-2 .content-right,
  .layout-3 .content-above {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .layout-4-content {
    column-count: 2;
    column-gap: calc(var(--gutter)*2);
  }

  .layout-5 .content-left {
    margin-bottom: calc(var(--gutter)*2);
  }

  .layout-2 .content-right a,
  .layout-1 .content-left a,
  .layout-3 .content-above a {
    width: 49%;
    margin-right: 2%;
  }

  .layout-1 .content-left a:nth-of-type(even),
  .layout-2 .content-right a:nth-of-type(even),
  .layout-3 .content-above a:nth-of-type(even) {
    margin-right: 0;
  }

  .content-below a:nth-of-type(2) img {
    margin-top: 0;
  }

  .layout-1 a.oneImg,
  .layout-2 a.oneImg,
  .content-wrapper .layout-3 a.oneImg {
    width: 100%;
    margin-right: 0;
  }

  /* Link nächste Seite */
  .next-page-wrapper {
    margin-top: calc(var(--gutter)*3);
    margin-bottom: calc(var(--gutter)*3);
  }
}

/* viewport-md 768px */
@media screen and (min-width: 48rem) {
  /* Layouts */
  .layout-2 .content-left {
    margin-bottom: 0;
  }

  .layout-5 .content-left {
    margin-bottom: 0;
  }

  .content-left a:last-of-type img,
  .content-right a:last-of-type img {
    margin-bottom: 0;
  }

  .layout-2 .content-right a,
  .layout-1 .content-left a {
    width: 100%;
    margin-right: 0;
  }

  .layout-1 .content-left a:nth-of-type(2n),
  .layout-2 .content-right a:nth-of-type(2n) {
    margin-right: 0;
  }

  .layout-1 .content-left,
  .layout-2 .content-left,
  .layout-5 .content-left {
    padding-right: var(--gutter);
  }

  .layout-1 .content-right,
  .layout-2 .content-right,
  .layout-5 .content-right {
    padding-left: var(--gutter);
  }

  .content-wrapper .layout-3 a.oneImg {
    width: 48rem;
    height: auto;
    margin-right: auto;
    margin-left: auto;
  }
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
  .layout-title h2,
  .layout-1 h2,
  .layout-2 h2,
  .layout-3 h2,
  .layout-4 h2,
  .layout-5 h2 {
    font-size: var(--font-size-h1);
  }

  .layout-title h3,
  .layout-1 h3,
  .layout-2 h3,
  .layout-3 h3,
  .layout-4 h3,
  .layout-5 h3 {
    font-size: var(--font-size-h2);
  }

  .layout-title h4,
  .layout-1 h4,
  .layout-2 h4,
  .layout-3 h4,
  .layout-4 h4,
  .layout-5 h4 {
    font-size: var(--font-size-h3);
  }

  .layout-1 h5,
  .layout-2 h5,
  .layout-3 h5,
  .layout-4 h5,
  .layout-5 h5 {
    font-size: var(--font-size-h4);
  }

  .layout-1 h6,
  .layout-2 h6,
  .layout-3 h6,
  .layout-4 h6,
  .layout-5 h6 {
    font-size: var(--font-size-h5);
  }


  .layout-3 .content-above a {
    width: 32%;
    margin-right: 2%;
  }

  .layout-3 .content-above a:nth-of-type(even) {
    margin-right: 2%;
  }

  .layout-3 .content-above a:nth-of-type(3n) {
    margin-right: 0;
  }
}

/* viewport-xl 1280px */
@media screen and (min-width: 80rem) {
  /* Layouts */
  article {
    padding-top: calc(var(--gutter)*4);
    padding-bottom: calc(var(--gutter)*4);
  }

  .layout-title {
    margin-bottom: calc(var(--gutter)*2);
  }

  .layout-1 .content-left,
  .layout-2 .content-left {
    padding-left: calc(var(--gutter)*3);
  }

  .layout-1 .content-right,
  .layout-2 .content-right {
    padding-right: calc(var(--gutter)*3);
  }

  .content-below a:nth-of-type(4) img {
    margin-top: 0;
  }

  .layout-3 .content-above a {
    width: 23.5%;
    margin-right: 2%;
  }

  .layout-3 .content-above a:nth-of-type(3n) {
    margin-right: 2%;
  }

  .layout-3 .content-above a:nth-of-type(4n) {
    margin-right: 0;
  }

  .layout-1  a.oneImg,
  .layout-2  a.oneImg {
    width: 100%;
    margin-right: 0;
  }

  /* Link nächste Seite */
  .next-page-wrapper {
    margin-top: calc(var(--gutter)*4);
    margin-bottom: calc(var(--gutter)*4);
  }
}

/* viewport-xxl 1600px */
@media screen and (min-width: 100rem) {
  .layout-1 .content-left,
  .layout-2 .content-left {
    padding-left: calc(var(--gutter)*8);
  }

  .layout-1 .content-right,
  .layout-2 .content-right {
    padding-right: calc(var(--gutter)*8);
  }
}
