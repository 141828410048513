/* allgemeine Infos und viewport-sx 320px */
/* Kontaktformular Startseite */

/* Kontaktformular Unterseite */
.kontaktformular {
  padding-top: var(--gutter-horizontal);
  padding-bottom: var(--gutter-horizontal);
}

#contactBottem,
#ctaContact {
  margin-left: -7px;
}

#contactBottem input,
#contactBottem textarea {
  box-shadow: none;
}

.kontaktdaten-wrapper {
  padding-top: var(--gutter-horizontal);
}

.kontaktdaten-daten p {
  margin-bottom: 0;
}

.kontaktdaten-daten a {
  display: block;
  font-size: var(--font-size);
  line-height: 1.5;
  color: var(--font-color);
}

.kontaktdaten-daten a:hover {
  color: var(--color-primary);
}

#contactBottem button,
#ctaContact button {
  margin-top: var(--gutter);
}

/* Maps */
.maps-container {
  margin-top: var(--gutter-horizontal);
  width: 100%;
  height: 10rem;
}

/* viewport-sm 568px */
@media screen and (min-width: 35.5rem) {
  /* Kontaktformular Startseite */
  .cta-formular-wrapper {
    background-image: url('img/muster-grau.jpg');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: cover;
    padding: var(--gutter);
    margin-top: calc(var(--gutter)*2);
  }

  .cta-formular-wrapper h4 {
    color: white;
  }

  /* Kontakformular Unterseite */
  #contactBottem,
  #ctaContact {
    margin-left: 0;
  }

  .kontaktformular {
    padding-top: calc(var(--gutter)*3);
    padding-bottom: calc(var(--gutter)*3);
  }

  .kontaktdaten-wrapper {
    padding-top: calc(var(--gutter)*3);
  }

  .kontaktdaten-adresse p {
    margin-bottom: 0;
  }

  /* Maps */
  .maps-container {
    margin-top: calc(var(--gutter)*3);
    height: 12rem;
  }
}

/* viewport-md 768px */
@media screen and (min-width: 48rem) {
  /* Kontaktformular Unterseiten */
  #contactBottem .info-form-vorname,
  #contactBottem .info-form-ort,
  #contactBottem .info-form-email {
    margin-right: calc(var(--gutter)*0.5);
  }

  #contactBottem .info-form-nachname,
  #contactBottem .info-form-plz,
  #contactBottem .info-form-telefon {
    margin-left: calc(var(--gutter)*0.5);
  }

  /* Maps */
  .maps-container {
    height: 15rem;
  }
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
  .cta-formular-wrapper {
    padding-top: var(--gutter);
    padding-right: calc(var(--gutter)*1.5);
    padding-left: calc(var(--gutter)*1.5);
    padding-bottom: calc(var(--gutter)*1.5);
  }
}

/* viewport-xl 1280px */
@media screen and (min-width: 80rem) {
  /* Kontakformular Unterseite */
  .kontaktformular {
    padding-top: calc(var(--gutter)*4);
    padding-bottom: calc(var(--gutter)*4);
  }

  .kontaktdaten-wrapper {
    padding-top: calc(var(--gutter)*4s);
  }

  /* Maps */
  .maps-container {
    margin-top: calc(var(--gutter)*4);
    height: 15rem;
  }
}

/* viewport-xxl 1600px */
@media screen and (min-width: 100rem) {
  /* Maps */
  .maps-container {
    height: 20rem;
  }
}
