/*
 * Filename: helpers.css
 *
 * Helper classes
 */

.visible,
.visible-xs {
  display: initial !important;
}

.visible-sm,
.visible-md,
.visible-lg,
.visible-xl,
.visible-xxl {
  display: none !important;
}

.hidden-xs {
  display: none !important;
}

.color-primary {
  color: var(--color-primary);
}

.color-secondary {
  color: var(--color-secondary);
}

.color-grey-light {
  color: var(--color-grey-light);
}

.color-grey-dark {
  color: var(--color-grey-dark);
}

.color-error {
  color: var(--error);
}

.color-success {
  color: var(--success);
}

.text-bold {
  font-family:'Tahoma W01 Bold';
  font-weight: normal;
}

.text-upper {
  text-transform: uppercase;
}

.btn-primary {
  display: inline-block;
  font-family:'Tahoma W01 Bold';
  font-weight: normal;
  color: white;
  padding: calc(var(--gutter)*0.5) var(--gutter);
  background-color: var(--color-primary);
  cursor: pointer;
}

.btn-primary:hover {
  background-color: var(--color-primary-hover);
}

.btn-white {
  display: inline-block;
  font-family:'Tahoma W01 Bold';
  font-weight: normal;
  color: var(--color-primary);
  padding: calc(var(--gutter)*0.5) var(--gutter);
  background-color: white;
  border: 2px solid var(--color-primary);
  cursor: pointer;
}

.btn-white:hover {
  color: white;
  background-color: var(--color-primary);
  border: 2px solid white;
}

.bottom-zero {
  margin-bottom: calc(var(--gutter)*0.3);
}

.error {
  display: inline-block;
  color: white;
  padding: 0.2rem 0.5rem;
  background-color: var(--error);
}

.border-error {
  border: 1px solid var(--error);
}

.success {
  display: inline-block;
  color: white;
  padding: 0.2rem 0.5rem;
  background-color: var(--success);
}

@media screen and (--viewport-sm) {
  .visible-xs,
  .visible-md,
  .visible-lg,
  .visible-xl,
  .visible-xxl {
    display: none !important;
  }

  .visible-sm {
    display: initial !important;
  }

  .hidden-xs {
    display: initial !important;
  }

  .hidden-sm {
    display: none !important;
  }
}

@media screen and (--viewport-md) {
  .visible-xs,
  .visible-sm,
  .visible-lg,
  .visible-xl,
  .visible-xxl {
    display: none !important;
  }

  .visible-md {
    display: initial !important;
  }

  .hidden-sm {
    display: initial !important;
  }

  .hidden-md {
    display: none !important;
  }
}

@media screen and (--viewport-lg) {
  .visible-xs,
  .visible-sm,
  .visible-md,
  .visible-xl,
  .visible-xxl {
    display: none !important;
  }

  .visible-lg {
    display: initial !important;
  }

  .hidden-md {
    display: initial !important;
  }

  .hidden-lg {
    display: none !important;
  }
}

@media screen and (--viewport-xl) {
  .visible-xs,
  .visible-sm,
  .visible-md,
  .visible-lg,
  .visible-xxl {
    display: none !important;
  }

  .visible-xl {
    display: initial !important;
  }

  .hidden-lg {
    display: initial !important;
  }

  .hidden-xl {
    display: none !important;
  }
}

@media screen and (--viewport-xxl) {
  .visible-xs,
  .visible-sm,
  .visible-md,
  .visible-lg,
  .visible-xl {
    display: none !important;
  }

  .visible-xxl {
    display: initial !important;
  }

  .hidden-xl {
    display: initial !important;
  }

  .hidden-xxl {
    display: none !important;
  }
}

