/* allgemeine Infos und viewport-sx 320px */
.btn-warenkorb-wrapper {
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  bottom: 0;
  left: 0;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 100;
}

.btn-warenkorb-wrapper a {
  display: flex;
  width: 100%;
  height: 3rem;
  justify-content: center;
  align-items: center;
  background-color: var(--color-primary);
}

.btn-warenkorb-wrapper a:hover {
  background-color: var(--color-primary-hover);
}

.btn-warenkorb-wrapper img {
  width: 1.5rem;
  height: 100%;
}

/* viewport-sm 568px */
@media screen and (min-width: 35.5rem) {
}

/* viewport-md 768px */
@media screen and (min-width: 48rem) {
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
  .btn-warenkorb-wrapper {
    width: 4rem;
    top: 45%;
    bottom: auto;
    left: auto;
    right: 0;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 100;
  }

  .btn-warenkorb-wrapper a {
    width: 4rem;
    height: 4rem;
    padding: 0;
  }

  .btn-warenkorb-wrapper img {
    width: 2rem;
  }
}

/* viewport-xl 1280px */
@media screen and (min-width: 80rem) {
}

/* viewport-xxl 1600px */
@media screen and (min-width: 100rem) {
}
