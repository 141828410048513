/* allgemeine Infos und viewport-sx 320px */
.startboxen-wrapper {
  padding-top: var(--gutter-horizontal);
  padding-bottom: var(--gutter-horizontal);
}

.startbox {
  text-align: center;
  margin-top: calc(var(--gutter) * 2);
}

.startbox:first-of-type {
  margin-top: 0;
}

.startbox-img {
  display: flex;
  width: 6rem;
  height: 6rem;
  border-radius: 3rem;
  justify-content: center;
  align-items: center;
  background-image: url('img/muster-icon.png');
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: var(--gutter-horizontal);
}

.startbox:first-of-type .startbox-img {
  background-color: var(--color-green-yellow);
}

.startbox:first-of-type h3 {
  color: var(--color-green-yellow);
}

.startbox:nth-of-type(2) .startbox-img {
  background-color: var(--color-green-light);
}

.startbox:nth-of-type(2) h3 {
  color: var(--color-green-light);
}

.startbox:nth-of-type(3) .startbox-img {
  background-color: var(--color-green);
}

.startbox:nth-of-type(3) h3 {
  color: var(--color-green);
}

.startbox:nth-of-type(4) .startbox-img {
  background-color: var(--color-green-dark);
}

.startbox:nth-of-type(4) h3 {
  color: var(--color-green-dark);
}

.startbox-img a {
  width: 4rem;
  height: 100%;
}

.startbox-img img {
  width: 100%;
  height: 100%;
}

.startbox h3 {
  font-family: 'Tahoma W01 Bold';
  font-weight: normal;
  font-size: var(--font-size-h5)
}

.startbox:first-of-type a {
  color: var(--color-green-yellow);
}

.startbox:nth-of-type(2) a {
 color: var(--color-green-light);
}

.startbox:nth-of-type(3) a {
  color: var(--color-green);
}

.startbox:nth-of-type(4) a {
  color: var(--color-green-dark);
}

.startbox a:hover {
  color: var(--color-primary);
}

.startbox a.color-grey-dark {
  color: var(--font-color);
}

hr.startbox-line {
  border: 0;
  height: 0;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  margin-top: 0;
  margin-bottom: 0;
  width: 80%;
}

/* viewport-sm 568px */
@media screen and (min-width: 35.5rem) {
  .startboxen-wrapper {
    padding-top: calc(var(--gutter) * 3);
    padding-bottom: calc(var(--gutter) * 3);
  }

  .startboxen-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .startbox {
    width: 47%;
    margin-right: 6%;
  }

  .startbox:nth-of-type(2) {
    margin-top: 0;
  }

  .startbox:nth-of-type(even) {
    margin-right: 0;
  }
}

/* viewport-md 768px */
@media screen and (min-width: 48rem) {
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
  .startbox {
    width: 20.5%;
    margin-right: 6%;
    margin-top: 0;
  }

  .startbox:nth-of-type(even) {
    margin-right: 6%;
  }

  .startbox:nth-of-type(4n) {
    margin-right: 0;
  }
}

/* viewport-xl 1280px */
@media screen and (min-width: 80rem) {
  .startboxen-wrapper {
    padding-top: calc(var(--gutter) * 4);
    padding-bottom: calc(var(--gutter) * 4);
  }
}

/* viewport-xxl 1600px */
@media screen and (min-width: 100rem) {
  .startbox {
    width: 22.5%;
    margin-right: 3.33%;
    margin-top: 0;
  }

  .startbox:nth-of-type(even) {
    margin-right: 3.33%;
  }

  .startbox:nth-of-type(4n) {
    margin-right: 0;
  }
}
