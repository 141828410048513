/*
 * Filename: forms.css
 *
 * Default Styles for Forms.
 */

label,
.pure-form label {
  display: block;
}

textarea,
.pure-form textarea {
  display: block;
}

fieldset,
.pure-form fieldset {
  padding: 0;
}

#contactBottem input,
#contactBottem select,
#contactBottem textarea,
#ctaContact input,
#ctaContact select,
#ctaContact textarea {
  margin-bottom: calc(var(--gutter)*0.5);
}
